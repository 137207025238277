 .menu-pics-body {
     background-color: #443501;
 }

 .menu-pics-body .small-logo {
     padding-top: 1em;
 }

 .menu-pics-body .menu-pics-section h1.title,
 .menu-pics-body .menu-pics-section h2.title {
     text-align: center;
     color: #ffecb3;
     padding: 1em;
     font-weight: 900;
 }

 .menu-pics-body .menu-pics-section h2.title {
     border-bottom: 2px solid #ffecb3;
 }

 .menu-pics-body .menu-pics {
     padding: 5px;
     color: #000;
     display: flex;
     flex-wrap: wrap;
 }

 .menu-pics-body .menu-item {
     background-color: #fff;
     width: 350px;
     margin: 1em auto;
 }

 .menu-pics-body .menu-item .menu-item-picture {
     padding: 1em;
 }

 .menu-pics-body .menu-item .menu-item-picture img {
     height: 350px;
     width: 100%;
     object-fit: cover;
     overflow: hidden;
 }

 .menu-pics-body .menu-item .menu-item-name {
     font-weight: 900;
     padding: 1em;
     text-align: center;
     text-transform: uppercase;
     cursor: pointer;
 }

 .menu-pics-body .mission {
    background-color: #d0c1b9;
    color: #614d42;
    font-style: italic;
    padding: 2em;
}