.home .membership .title {
    color: #ffecb3;
}

.home section.membership {
    background-color: #443501;
    margin-bottom: 0;
}

.home .small-logo {
    width: 100px;
}

.home section.membership p,
.home section.membership ul {
    margin: 2em 0;
}

.home section.membership ul,
.home section.dark ol {
    margin-left: 1em;
}

.home .membership ul {
    list-style-type: disc;
    text-align: left;
}