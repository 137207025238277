
.home .hero-banner {
    position: relative;
    background: 0 0 url(./images/pic.webp) no-repeat;
    background-size: cover;
    height: 900px;
    color: #FFF;
}

.home .banner-logo {
    left: 30%;
    width: 40%;
    background-image: url(./images/logo.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.home .banner-text,
.home .banner-logo {
    position: absolute;
    top: 20%;
    height: 60%;
    z-index: 3;
}

.home .banner-text {
    font-size: 1rem;
    left: 10%;
    width: 80%;
}

.home .banner-text h2 {
    color: inherit;
    font-family: 'Kristi', "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    font-size: 8rem;
    font-weight: 400;
    text-transform: none;
}

@media only screen and (max-width: 600px) {
    .home .banner-logo {
        left: 0%;
        width: 100%;
    }

    .home .banner-text h2 {
        font-size: 5rem;
    }
}

@media only screen and (max-width: 990px) {
    .home .banner-logo {
        left: 0%;
        width: 100%;
    }

    .home .banner-text h2 {
        font-size: 5rem;
    }
}
