@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

.reservations-root {
  padding: 50px;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  color: #555;
}

.container-fluid.reservations {
  background-color: #fff;
  box-shadow: 0 0.46875rem 2.1875rem rgb(97 77 66 / 3%), 0 0.9375rem 1.40625rem rgb(97 77 66 / 3%), 0 0.25rem 0.53125rem rgb(97 77 66 / 5%), 0 0.125rem 0.1875rem rgb(97 77 66 / 3%);
  margin: 0 auto;
  padding: 2em;
}

.reservations a {
  color: #00B7FF;
}

.reservations .center {
  text-align: center;
}

.reservations .form-input div {
  margin: 5px 0;
}

.reservations .form-input input,
.reservations .form-input textarea {
  width: calc(100% - 3em);
  padding: 1em;
  border: 0px;
  border-bottom: 1px solid #dfdfdf;
  color: #0c254b;
}

.reservations label {
  font-weight: 700;
}

.reservations h1 {
  color: #8e6a06;
  border-bottom: 1px solid #8e6a06;
}

.reservations h2 {
  color: #8e6a06;
}

.reservations .btn, .reservations .btn:hover {
  background-color: #8e6a06;
  color: #fff;
  font-weight: 900;
  padding: 1em;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

.reservations .fas {
  padding-right: 1em;
}

.reservations .fas {
  color: #8e6a06;
}

.reservations .row {
  margin-bottom: 1em;
}

.reservations .description {
  padding-right: 1em;
  margin-right: 1em;
  border-right: 2px solid #eee;
  color: #888;
}

.reservations .time-slots {
    padding: 0;
}

.reservations .time-slots li {
    list-style: none;
    padding: 2px 1em;
    margin: 5px 5px 0 0;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #9a8c29;
    color: #9a8c29;
    background-color: #fff;
}

.reservations .time-slots li.active {
    background-color: #9a8c29;
    color: #fff;
}

@media only screen and (max-width: 760px) {
  body {
    padding: 5px;
  }

  .container-fluid.reservations {
    padding: 2em 1em ;
  }

  .reservations .description {
    padding-right: auto;
    margin-right: auto;
    border-right: 0 solid #eee;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 2px solid #eee;
    color: #888;
  }

  .reservations .val {
    background-color: #f4f4f4;
    padding: 1em;
  }
}

.reservations .alert {
  background-color: #f1ebc5;
  border: 0px;
  padding: 1.5em;
  width: 100%;
}

.reservations .form-input textarea::placeholder {
  transform: translateY(35px);
}

.clickable {
  cursor: pointer;
  font-weight: 900;
}

.pull-right {
  float: right;
}
