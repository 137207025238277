.home .pictures {
    box-sizing: border-box;
    position: relative;
    /* margin-top: 5em; */
    margin-bottom: 5px;
}

.home .pictures .row>.column {
    padding: 0 8px;
}

.home .pictures .row:after {
    content: "";
    display: table;
    clear: both;
}

.home .pictures .column {
    float: left;
    width: 25%;
}

/* The Modal (background) */
.home .pictures .pic-modal {
    /* display: none; */
    /* position: fixed; */
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
}

/* Modal Content */
.home .pictures .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 90%;
}

/* The Close Button */
.home .pictures .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 2.5rem;
    font-weight: bold;
}

.home .pictures .close:hover,
.home .pictures .close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
}

.home .pictures .gallery-images {
    display: none;
}

.home .pictures .gallery-images.active {
    display: block;
}

.home .pictures .gallery-images img {
    width: 100%;
}

.home .pictures .cursor {
    cursor: pointer;
}

/* Next & previous buttons */
.home .pictures .prev,
.home .pictures .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
}

/* Position the "next button" to the right */
.home .pictures .next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* Number text (1/3 etc) */
.home .pictures .numbertext {
    color: #ffffff;
    background-color: black;
    font-size: 0.8rem;
    padding: 8px 12px;
    position: absolute;
    top: 0;
}

.home .pictures img {
    margin-bottom: -4px;
}

.home .pictures .active,
.home .pictures .demo:hover {
    opacity: 1;
}

.home .pictures img.hover-shadow {
    transition: 0.3s;
}