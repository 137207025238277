.header {
    background-color: #443501;
}

.header li.nav-item {
    cursor: pointer;
}

.header li.nav-item img {
    vertical-align: bottom;
}

.header li.nav-item.flex {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: inherit;
}