.home {
    background-color: #ffffff;
    color: #757575;
    font-family: "Poppins", "Georgia", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.home section {
    max-width: 100%;
    overflow-x: hidden;
}

.home h1,
.home h2,
.home h3,
.home h4,
.home h5,
.home h6,
.home p.lead,
.home .block-info p,
.home .header .logo h2,
.home nav,
.home ul {
    font-family: inherit;
}

.home h1,
.home h2,
.home h3,
.home h4,
.home h5,
.home h6 {
    color: #353031;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
}

.home h1.title,
.home h2.title {
    color: #353031;
}


.home .main-nav {
    color: #FFFFFF;
    z-index: 99;
}

.home .main-nav.sticky {
    background-color: #443501;
}

.home section {
    margin: 2em 0;
}

.home a.reserve-now {
    background-color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    color: #333333;
    font-family: Arial;
    font-size: 17px;
    padding: 14px 31px;
    text-decoration: none;
}

.home .reservations {
    position: relative;
    background: url(./images/pic3.webp) no-repeat;
    background-size: cover;
    background-position: center;
    height: 600px;
}

.home .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #000;
    opacity: 0.7;
    color: #fff;
}

.home .title {
    font-size: 2rem;
    font-weight: 400;
    text-transform: capitalize;
}

.home .beige {
    color: #a68b7c;
    text-transform: capitalize;
    font-size: 0.8rem;
}

.home .beige.main-text {
    font-size: 100%;
    line-height: 2rem;
    margin: 1rem 0;
    text-transform: none;
    font-style: italic;
}

.home .main-text.top-margin {
    margin-top: 1.5em;
}

.home p {
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 1rem;
}

.home ul {
    list-style: none;
}

.home .spacer {
    display: block;
    width: 100%;
    height: 3rem;
}

.home .nav .nav-item {
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin: 0 -2px;
    padding: 0 28px;
    position: relative;
    top: 1px;
    color: #353031;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.home .nav .active {
    border-bottom: 2px solid #b0988b;
}

.home .tab-pane {
    padding: 1em;
}

.home .block-cat h2,
.home .block-cat h3,
.home h2.large {
    font-family: 'Kristi', "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.home .subheading {
    color: #a68b7c;
    text-transform: none;
}

.home .subheading .small {
    font-size: 0.7rem;
    line-height: inherit;
    color: inherit;
}

.home .block-cat h2,
.home .block-cat h3 {
    font-size: 3.5rem;
    font-weight: 400;
    text-transform: none;
    color: #a68b7c;
}

.home .block-content {
    position: relative;
    margin-bottom: 5px;
}

.home .block-content .subheading,
.home .block-content .menu-item-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 700;
}

.home .block-content .menu-item-title {
    color: #353031;
    text-transform: uppercase;
    font-weight: 700;
}

.home .block-content .subheading div {
    max-width: 70%;
}

.home .block-cat p,
.home .block-content p {
    font-size: 0.8rem;
}

.home .block-price {
    color: #a68b7c;
    font-weight: 700;
}

.home .dots {
    background-image: radial-gradient(circle closest-side, rgba(176, 152, 139, 0.25) 80%, #fff 10%);
    background-position: center bottom;
    background-repeat: repeat-x;
    background-size: 4px 2px;
    border: 0 none;
    display: block;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.home .nav.justify-content-center {
    margin: 0 auto;
    text-align: center;
}

.home .menu-nav .nav-item {
    padding: 0 5px;
}

.home a,
.home .nav-link {
    color: inherit;
    text-decoration: none;
}

.home a:hover,
.home a:focus {
    color: inherit;
    text-decoration: none;
}

.home .nav-link:hover,
.home .active:hover,
.home .nav-link:focus,
.home .active:focus {
    background-color: inherit !important;
    color: inherit !important;
}

.home .tab-pane {
    display: none;
}

.home .tab-pane.active {
    display: flex;
}

.home .flex {
    display: flex;
}

.home .flex span {
    width: 100%;
}

.home .flex.block-price {
    width: 25%;
    justify-content: space-between;
}

.home .social-media li {
    font-size: 2rem;
}

.home .mobile-menu {
    display: none;
}

.home .foodpanda-icon {
    max-width: 100px;
}

.home .mission {
    background-color: #d0c1b9;
    font-style: italic;
    color: #614d42;
    padding: 2em;
}

.home .mission h2.title {
    color: inherit;
    margin-bottom: 1em;
}

.home .order-now-btn,
.home .order-now-btn:visited,
.home .order-now-btn:hover {
    background-color: #a68b7c;
    border: 1px solid #a68b7c;
    color: #ffffff;
    padding: 10px;
    margin: 10px;
    font-weight: 600;
    cursor: pointer;
    min-width: 10%;
}

.home section.membership {
    padding: 3em 0;
    color: rgba(255, 255, 255, 0.7);
    background-color: #480d0d;
}

.home section li {
    margin-top: 1em;
}

.home .menu-pics-body {
    background-color: #443501;
}

.home .menu-pics-body .small-logo {
    padding-top: 1em;
}

.home .menu-pics-section h1.title,
.home .menu-pics-section h2.title {
    text-align: center;
    color: #ffecb3;
    padding: 1em;
    font-weight: 900;
}

.home .menu-pics-section h2.title {
    border-bottom: 2px solid #ffecb3;
}

.home .menu-pics {
    padding: 5px;
    color: #000;
    display: flex;
    flex-wrap: wrap;
}

.home .menu-item {
    background-color: #fff;
    width: 350px;
    margin: 1em auto;
}

.home .menu-item .menu-item-picture {
    padding: 1em;
}

.home .menu-item .menu-item-picture img {
    height: 350px;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.home .menu-item .menu-item-name {
    font-weight: 900;
    padding: 1em;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
}

.home .center-align {
    margin: 0 auto;
    width: 100px;
}

.home .investors {
    /* background-color: #000;
    color: #fff; */
}

.home .investors .growth-intro {
    margin: 1em 0;
    padding: 1em;
}

.home .investors .growth-intro img {
    width: 40%;
}

.home .menu-pics-body .modal-content {
    padding: 2em;
}

@media only screen and (max-width: 600px) {
    .home .social-media {
        display: flex;
    }

    .home .social-media li {
        padding-right: 20px;
    }
}

@media only screen and (max-width: 990px) {
    .home section#awards img {
        max-width: 100%;
    }

    .home .social-media {
        display: flex;
    }

    .home .social-media li {
        padding-right: 20px;
    }

    .home .ftco-footer h2 {
        margin: 30px 0 10px;
    }

    .home .menu-item {
        width: 100%;
    }

    .home .menu-item .menu-item-picture img {
        height: 450px;
    }

    .home h2 {
        font-size: 1.2rem;
    }

    .home .block-content {
        padding: 5px;
    }

    .home .subheading .small,
    .home .beige,
    .home .nav .nav-item,
    .home .block-cat p,
    .home .block-content p,
    .home .ftco-footer,
    .home .pictures .numbertext {
        font-size: 1rem;
    }

}