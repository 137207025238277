.ftco-footer {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
    font-family: "Poppins", Arial, sans-serif;
    background: #141313;
    padding: 6em 0;
}

.ftco-footer h2 {
    font-weight: normal;
    color: #fff;
    margin-bottom: 40px;
    font-size: 1rem;
    font-weight: 700;
}

.ftco-footer a {
    color: inherit;
}

.ftco-footer .flex {
    display: flex;
}

.ftco-footer .flex span {
    width: 100%;
}

.social-media li {
    font-size: 2rem;
}

.foodpanda-icon {
    max-width: 100px;
}

.inheritBg {
    background-color: inherit;
}

@media only screen and (max-width: 600px) {
    .ftco-footer h2 {
        margin: 30px 0 10px;
    }

    .social-media {
        display: flex;
    }

    .social-media li {
        padding-right: 20px;
    }
}

@media only screen and (max-width: 990px) {
    .social-media {
        display: flex;
    }

    .social-media li {
        padding-right: 20px;
    }

    .ftco-footer h2 {
        margin: 30px 0 10px;
    }

    .ftco-footer {
        font-size: 1rem;
    }

}