.shop.container {
    padding: 2em;
    margin-right: auto;
    margin-left: auto;
  }

  .shop .logo {
    max-width: 200px;
    margin: 2em auto;
  }
  
  @media (min-width: 768px) {
    .shop.container {
        width: 750px;
    }
  }
  
  @media (min-width: 992px) {
    .shop.container {
        width: 970px;
    }
  }
  
  .shop .title {
    font-size: inherit;
    font-weight: bold;
    line-height: inherit;
    color: #a68b7c;
  }
  
  .shop button {
    background-color: #a68b7c;
    border: 0px;
    color: #ffffff;
    padding: 10px;
    margin: 10px;
    font-weight: 600;
    cursor: pointer;
    min-width: 20%;
  }
  
  .shop .inactive {
    background-color: #f8dfbe; 
    color: #333333;
  }
  
  .shop .formLabel {
    /* font-weight: 600; */
    color: #a68b7c;
    margin-top: 1.5em;
  }
  
  .shop input[type="text"], .shop input[type="email"], .shop select {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 75%;
    padding: 5px;
    border: 1px solid #ebd8ce;
    margin: 0.5em 0;
    font-size: inherit;
  }
  
  .shop select {
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 99% 50%;
      -moz-appearance: none; 
      -webkit-appearance: none; 
      appearance: none;
  
    border-radius: 0;
    background-color: #ffffff;
  }
  
  .shop .half-columns {
    display: flex;
    width: 75%;
  }
  
  @media only screen and (max-width: 600px) {
    .shop button {
      min-width: 40%;
    }
  
    .shop input[type="text"], .shop select {
      width: 100%;
    }
  
    .shop .half-columns {
      display: flex;
      width: 100%;
      gap: 1em;
    }
  }
  
  .shop .half-columns div, .shop .half-columns input {
    width: 98%;
  }
  
  .shop .errorMsg, .shop .infoMsg {
    color: white;
    background-color: rgb(206, 59, 59);
    padding: 1em;
  }
  
  .shop .infoMsg {
    color: #333333;
    background-color: rgb(206, 231, 248);
    padding: 1em;
  }