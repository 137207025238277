.promotions {
    background-color: #443501;
    background-image: url(./christmas-bg.jpg);
    background-size: cover;
    margin: -50px;
    padding: 50px;
    min-height: 1500px;
}

.promotions .promotions-content {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    z-index: 101;
}

.promotions .promotions-content .core-content {
    padding: 4em 0;
    min-height: 600px;
    line-height: 2em;
}

.promotions .promotions-content h1 {
    color: #ffecb3;
}

.promotions .promotions-content p {
    width: 50%;
    padding: 1em 0;
    margin: 0 auto;
}

.promotions .overlay {
    background-color: #000;
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
}

.promotions .attribution {
    color: inherit;
    font-size: 0.7em;
}

.promotions .attribution a {
    color: inherit;
}

.promotions .promo-img {
    width: 30%;
    margin: 1em 0;
    padding: 5px;
    background-color: #443501;
    min-width: 600px;
    max-height: 600px;
    object-fit: contain;
}

.promotions .promo-img.no-bg {
    background-color: inherit;
}

@media only screen and (max-width: 600px) {
    .promotions {
        margin: 0;
    }
    .promotions .promotions-content {
        padding: 1em;
    }

    .promotions .promotions-content p {
        width: auto;
    }

    .promotions .promo-img {
        width: 100%;
        min-width: inherit;
    }
    
    .promotions .overlay {
        opacity: 0.85;
    }
}
