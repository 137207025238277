.chat-launcher,
.chat-chat-window {
    z-index: 100;
    font-family: inherit;
}

.chat-header,
.chat-launcher,
.chat-message-avatar {
    background-color: #443501;
}

.chat-message-content.sent .chat-message-text {
    background-color: #d0c1b9;
    color: #614d42;
}

.chat-chat-window {
    height: calc(100% - 200px);
    position: relative;
}

.chat-launcher {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transition: box-shadow .2s ease-in-out;
    color: #fff;
    font-size: 2em;
    text-align: center;
}

.chat-launcher i {
    vertical-align: middle;
}

.chat-window {
    position: fixed;
    bottom: 75px;
    right: 10px;
    z-index: 100;
    background-color: #fff;
    height: calc(100% - 200px);
    
    width: 370px;
    max-height: 590px;
    box-sizing: border-box;
    box-shadow: 0 7px 40px 2px rgba(148,149,150,.3);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: .3s ease-in-out;
    border-radius: 10px;
}

.chat-message-list {
    font-size: 0.9em;
    padding: 10px;
    height: calc(100% - 150px);
    overflow-y: auto;
    margin-bottom: 3em;
}

@media (max-width: 450px) {
    .chat-window {
        height: calc(100% - 75px);
        bottom: 0;
        right: 0;
        width: 100%;
    }

    .chat-launcher.open {
        display: none;
    }
}

.chat-header, .chat-message-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
}

.chat-message-avatar {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-right: 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
}

.chat-message-content.sent {
    justify-content: flex-end;
}

.chat-message-content.sent .chat-message-avatar {
    display: none;
}

.chat-header {
    padding: 1em;
    justify-content: space-between;
    color: #fff;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

.chat-header .chat-header-img {
    width: 20%;
}

.chat-header .chat-header-team-name {
    width: 70%;
}

.chat-header .chat-header-close-button {
    cursor: pointer;
}

.chat-message-content.received .chat-message-text {
    color: #263238;
    background-color: #f4f7f9;
    margin-right: 40px;
}

.chat-message-text {
    width: 75%;
    padding: 1em;
    border-radius: 5px;
}

.chat-user-input {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    padding: 2px 5px;
    align-items: center;
    width: 100%;
    background-color: #eee;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    border: 1px solid #ccc;
}

.chat-user-input-text:focus {
    outline: none;
    outline-width: 0;
}

.chat-user-input * {
    height: 100%;
    background-color: transparent;
    border: none;
}

.chat-user-input-text {
    padding: 1em;
    width: calc(100% - 30px);
    font-family: inherit;
    font-size: 0.9em;
}

.chat-user-input-send {
    width: 30px;
}