  .catalog.container {
    display: flex;
    width: 100%;
    min-height: 600px;
    justify-content: space-between;
  }
  
  .product-menu {
    width: 75%;
    padding: 1em;
  }
  
  .basket {
    width: 25%;
    min-width: 22em;
    padding: 1em;
    background-color: #d0c1b9ad;
  }
  
  @media only screen and (max-width: 600px) {
    .catalog.container {
      display: block;
    }

    .product-menu {
      width: calc(100% - 2em);
    }
    
    .basket {
      width: calc(100% - 2em);
    }
  }

  .product {
    border-bottom: 1px dotted;
    padding: 1em 2em;
    gap: 1em;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .product .product-name-container {
    width: 60%;
  }

  @media only screen and (max-width: 600px) {
    .product .product-name-container {
      width: 85%;
    }
  }

  .product .product-name {
    font-weight: 600;
  }

  .product .product-description {
    font-size: 0.85em;
  }

  .product .product-price {
    width: 30%;
  }
  .product .product-action {
    width: 10%;
  }

  .product .section-title {
    color: #a68b7c;
  }
  
  .catalog button, .catalog input[type="submit"], .popup-content button {
    background-color: #a68b7c;
    border: 0px;
    border-radius: 1px;
    color: #ffffff;
    padding: 10px;
    font-weight: 600;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    width: 100%;
    min-width: 3em;
  }

  .basket-line-item {
    border-bottom: 1px dotted;
    padding: 10px 1em;
  }
  
  .catalog .basket-line-item .row {
    gap: 5px;
  }

  .basket-line-item .item-name {
    width: 12em;
  }

  .basket .delete-icon {
    cursor: pointer;
  }
  
  .catalog .row, .price-header .row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .catalog .top_margin {
    margin-top: 1.5em;
  }
  
  .price-header {
    position: fixed;
    padding: 1em;
    background-color: #d0c1b9ad;
    top: 0;
    width: calc(100% - 2em);
  }

  .price-header .simpleText {
    color: inherit;
    text-decoration: inherit;
  }

  .product-options {
    display: none;
  }

  .product-options.popup-active {
    display: block;
  }

  .product-options .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: #000000;
    opacity: 0.8;
  }

  .product-options .popup-body {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    max-height: 75%;
    overflow: scroll;
    background-color: #ffffff;
    border-radius: 10px;
    border-top: 10px solid #d0c1b9;
    border-bottom: 10px solid #d0c1b9;
    z-index: 10;
  }

  .product-options .popup-body label {
    margin-left: 1em;
  }

  .product-options .popup-body .popup-content {
    padding: 2em;
  }

  .product-options .popup-body .popup-content ul ul {
    padding: 0;
    margin-bottom: 3em;
  }

  .product-options .popup-body .selected-product-name {
    background-color: #d0c1b9;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .product-options .popup-body .selected-product-name h3 {
    margin: 0;
    padding: 1em;
  }

  .product-options .popup-body .selected-product-name .close-icon {
    cursor: pointer;
    padding: 0.3em;
    font-size: 2em;
    color: #a68b7c;
  }

  .product-options .popup-body .popup-content input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #d0c1b9;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #d0c1b9;
    border-radius: 50%;
    transform: translateY(-0.075em);
  }

  .product-options .popup-body .popup-content input[type="radio"] {
    /* ...existing styles */
    display: grid;
    place-content: center;
  }
  
  .product-options .popup-body .popup-content input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #a68b7c;
  }
  
  .product-options .popup-body .popup-content input[type="radio"]:checked::before {
    transform: scale(1);
  }

  .product-options .popup-body .popup-content .form-control {
    margin-top: 1em;
    display: grid;
    grid-template-columns: 1em auto;
    gap: 0.5em;
    align-items: center;
  }

  .product-options .popup-body .popup-content .form-control input:checked ~ span {
    /* color: #c7642bb6; */
    font-weight: 600;
  }

  .basket .min-order-msg {
    font-style: italic;
  }